import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";

// import ComingSoonShop from '../assets/images/norrm-shop-banner.jpg'
// import ShopSection from "../components/sections/shops";

import imageIcon from "../assets/images/4PM.png";
import imagePin from "../assets/images/norrmradiobar-pin.png";
import imageHeader from "../assets/images/header operational.png";
import imageSaturday from "../assets/images/friday-saturday.png";
import imageSunday from "../assets/images/sunday-thursday.png";

const AnyReactComponent = ({ text }) => (
  <div
    onClick={() => {
      // console.log("ROUTE!");
      // window.location = "https://maps.app.goo.gl/cJt9btGSRLuo6LnX7";
      window.location =
        "https://www.google.com/maps/dir//Norrm+Radiobar,+Ruko+Segitiga+Emas+Kosambi,+Merdeka,+Sumurbandung,+Bandung+City,+West+Java+40113/@-6.9169767,107.6259659,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e68c3c52bbee1b9:0xa87493442a2a79ea!2m2!1d107.6259659!2d-6.9169767?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D";
    }}
    // className="blink"
    style={{
      color: "#fff",
      width: "50px",
      height: "50px",
      display: "flex",
      fontSize: "9px",
      borderRadius: "50%",
      textAlign: "center",
      alignItems: "center",
      alignContent: "center",
      backgroundColor: "#f00",
      justifyContent: "center",
      transform: "translateY(-75px) translateX(-25px)",
      cursor: "pointer",
    }}
  >
    <img src={imagePin} alt="Norrm RadioBar" style={{ width: "170px" }} />
    {/* {text} */}
  </div>
);

class Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Box component="div" className="show-page" fullwidth="true">
        <Box>
          <Box className="archive-container">
            <Box className="section-container-header">
              <Typography>Radio Bar</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              minHeight: 500,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
              position: "relative",
            }}
            className="radiobar-info-container"
          >
            <Box
              className="content-100 container-map"
              sx={{
                width: "100%",
                position: "relative",
              }}
            >
              <GoogleMapReact
                style={{ width: "100%", height: "100%" }}
                bootstrapURLKeys={{ key: "AIzaSyCoUIl4wu_ftf3ETRQO56GKElSl5dRjiV0" }}
                defaultCenter={{
                  lat: -6.916981,
                  lng: 107.625975,
                }}
                defaultZoom={17}
              >
                <AnyReactComponent lat={-6.916981} lng={107.625975} text="Norrm Radio bar" />
              </GoogleMapReact>
            </Box>
            <Box
              className="box-info-operational collapsed noselect"
              onClick={(e) => {
                // e.currentTarget.removeAttribute()
                // console.log("collapsed");
                if (window.innerWidth > 480) {
                  // console.log(e.target.classList);
                  e.currentTarget.classList.remove("collapsed");
                }
              }}
            >
              <img src={imageHeader} alt="Operational Hours" width={"80%"} className="img-operational-header" />
              <img src={imageIcon} alt="4PM" className="img-operational-icon" />
              <img src={imageSunday} alt="4PM Sunday" className="img-operational-sunday" />
              <img src={imageSaturday} alt="4PM Saturday" className="img-operational-saturday" />
            </Box>
            {/* <Box
              className="content-50"
              sx={{
                width: "50%",
                height: "500px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </Box> */}
          </Box>
        </Box>
        {/* -6.916981, 107.625975 */}
        {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
        {/* map google key */}
        {/* AIzaSyCoUIl4wu_ftf3ETRQO56GKElSl5dRjiV0 */}
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getPress: data => dispatch(getPress(data)),
    // loadMorePress: data => dispatch(loadMorePress(data)),
    // login: userData => dispatch(login(userData)),
    // getArtist: data => dispatch(getArtist(data)),
    // getShow: data => dispatch(getShow(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bar));
